/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import "typeface-nunito";
import "typeface-quicksand";
import Cookies from "js-cookie";
import debugLibrary from "debug";

const debug = debugLibrary("taplist:homepage");

const { NODE_ENV } = process.env;

const COOKIE_DOMAIN = NODE_ENV === "production" ? ".taplist.io" : undefined;

export function getMarketingUrlParams(location) {
  const params = new URLSearchParams(location.search);
  const utmCampaign = params.get("utm_campaign") || null;
  const utmSource = params.get("utm_source") || null;

  return {
    utm_source: utmSource,
    utm_campaign: utmCampaign,
  };
}

export function saveMarketingCookies(cookies) {
  let changed = false;
  for (const [key, value] of Object.entries(cookies)) {
    if (value && value !== Cookies.get(key)) {
      debug(`Setting cookie ${key}=${value} domain=${COOKIE_DOMAIN}`);
      Cookies.set(key, value, { expires: 365, domain: COOKIE_DOMAIN });
      changed = true;
    }
  }
  if (changed) {
    debug("marketing cookies:", getMarketingCookies());
  }
}

function getMarketingCookies() {
  return {
    utm_source: Cookies.get("utm_source") || null,
    utm_campaign: Cookies.get("utm_campaign") || null,
  };
}

export function checkAndSetMarketingCookies(location) {
  const cookies = getMarketingUrlParams(location);
  saveMarketingCookies(cookies);
}

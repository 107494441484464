/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import "typeface-nunito";
import "typeface-quicksand";

import { checkAndSetMarketingCookies } from "./src/util/cookies";

export function shouldUpdateScroll(prevRouterProps, { location }) {
  // window.scrollTo(0, 0);
  // const body = document.getElementsByTagName("body")[0];
  // body.scrollTop = 0;
  return false;
}

export function onRouteUpdate({ location, prevLocation }) {
  checkAndSetMarketingCookies(location);
}

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-brewery-menus-js": () => import("./../../../src/pages/brewery-menus.js" /* webpackChunkName: "component---src-pages-brewery-menus-js" */),
  "component---src-pages-company-js": () => import("./../../../src/pages/company.js" /* webpackChunkName: "component---src-pages-company-js" */),
  "component---src-pages-digitalpour-js": () => import("./../../../src/pages/digitalpour.js" /* webpackChunkName: "component---src-pages-digitalpour-js" */),
  "component---src-pages-features-js": () => import("./../../../src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-gift-cards-index-js": () => import("./../../../src/pages/gift-cards/index.js" /* webpackChunkName: "component---src-pages-gift-cards-index-js" */),
  "component---src-pages-gift-cards-purchased-js": () => import("./../../../src/pages/gift-cards/purchased.js" /* webpackChunkName: "component---src-pages-gift-cards-purchased-js" */),
  "component---src-pages-hardware-apple-tv-js": () => import("./../../../src/pages/hardware/apple-tv.js" /* webpackChunkName: "component---src-pages-hardware-apple-tv-js" */),
  "component---src-pages-hardware-fire-tv-js": () => import("./../../../src/pages/hardware/fire-tv.js" /* webpackChunkName: "component---src-pages-hardware-fire-tv-js" */),
  "component---src-pages-hardware-index-js": () => import("./../../../src/pages/hardware/index.js" /* webpackChunkName: "component---src-pages-hardware-index-js" */),
  "component---src-pages-hardware-raspberry-pi-js": () => import("./../../../src/pages/hardware/raspberry-pi.js" /* webpackChunkName: "component---src-pages-hardware-raspberry-pi-js" */),
  "component---src-pages-help-index-js": () => import("./../../../src/pages/help/index.js" /* webpackChunkName: "component---src-pages-help-index-js" */),
  "component---src-pages-help-supported-hardware-js": () => import("./../../../src/pages/help/supported-hardware.js" /* webpackChunkName: "component---src-pages-help-supported-hardware-js" */),
  "component---src-pages-homebrew-js": () => import("./../../../src/pages/homebrew.js" /* webpackChunkName: "component---src-pages-homebrew-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-integrations-google-js": () => import("./../../../src/pages/integrations/google.js" /* webpackChunkName: "component---src-pages-integrations-google-js" */),
  "component---src-pages-integrations-gotab-js": () => import("./../../../src/pages/integrations/gotab.js" /* webpackChunkName: "component---src-pages-integrations-gotab-js" */),
  "component---src-pages-integrations-index-js": () => import("./../../../src/pages/integrations/index.js" /* webpackChunkName: "component---src-pages-integrations-index-js" */),
  "component---src-pages-integrations-kegtron-js": () => import("./../../../src/pages/integrations/kegtron.js" /* webpackChunkName: "component---src-pages-integrations-kegtron-js" */),
  "component---src-pages-integrations-raydiant-js": () => import("./../../../src/pages/integrations/raydiant.js" /* webpackChunkName: "component---src-pages-integrations-raydiant-js" */),
  "component---src-pages-live-tap-lists-js": () => import("./../../../src/pages/live-tap-lists.js" /* webpackChunkName: "component---src-pages-live-tap-lists-js" */),
  "component---src-pages-partner-gotab-submit-lead-jsx": () => import("./../../../src/pages/partner/gotab/submit-lead.jsx" /* webpackChunkName: "component---src-pages-partner-gotab-submit-lead-jsx" */),
  "component---src-pages-pos-js": () => import("./../../../src/pages/pos.js" /* webpackChunkName: "component---src-pages-pos-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-promo-cbp-js": () => import("./../../../src/pages/promo/cbp.js" /* webpackChunkName: "component---src-pages-promo-cbp-js" */),
  "component---src-pages-refund-policy-js": () => import("./../../../src/pages/refund-policy.js" /* webpackChunkName: "component---src-pages-refund-policy-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */)
}

